/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import MediumRelatedArticlesUpdateValuesInner from './MediumRelatedArticlesUpdateValuesInner';





/**
* The MediumRelatedArticlesUpdateValues model module.
* @module model/MediumRelatedArticlesUpdateValues
* @version 1.1.71
*/
export default class MediumRelatedArticlesUpdateValues extends Array {
    /**
    * Constructs a new <code>MediumRelatedArticlesUpdateValues</code>.
    * @alias module:model/MediumRelatedArticlesUpdateValues
    * @class
    */

    constructor() {
        
        super();
        


        

        return this;
    }

    /**
    * Constructs a <code>MediumRelatedArticlesUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/MediumRelatedArticlesUpdateValues} obj Optional instance to populate.
    * @return {module:model/MediumRelatedArticlesUpdateValues} The populated <code>MediumRelatedArticlesUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MediumRelatedArticlesUpdateValues();

            ApiClient.constructFromObject(data, obj, 'MediumRelatedArticlesUpdateValuesInner');

            
        }
        return obj;
    }









}


