/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DraftArticle from '../model/DraftArticle';
import NewDraftArticles from '../model/NewDraftArticles';

/**
* Draft service.
* @module api/DraftApi
* @version 1.1.71
*/
export default class DraftApi {

    /**
    * Constructs a new DraftApi. 
    * @alias module:api/DraftApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 下書き記事の削除
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteDraftArticleWithHttpInfo(articleId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling deleteDraftArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/draftArticles/{articleId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 下書き記事の削除
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteDraftArticle(articleId) {
      return this.deleteDraftArticleWithHttpInfo(articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 下書き記事の取得
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DraftArticle} and HTTP response
     */
    getDraftArticleWithHttpInfo(articleId) {
      let postBody = null;

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling getDraftArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = DraftArticle;

      return this.apiClient.callApi(
        '/draftArticles/{articleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 下書き記事の取得
     * @param {Number} articleId 記事の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DraftArticle}
     */
    getDraftArticle(articleId) {
      return this.getDraftArticleWithHttpInfo(articleId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 下書き記事の追加
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/NewDraftArticles} opts.newDraftArticles 追加する下書き記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    postDraftArticleWithHttpInfo(articleId, opts) {
      opts = opts || {};
      let postBody = opts['newDraftArticles'];

      // verify the required parameter 'articleId' is set
      if (articleId === undefined || articleId === null) {
        throw new Error("Missing the required parameter 'articleId' when calling postDraftArticle");
      }


      let pathParams = {
        'articleId': articleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/draftArticles/{articleId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 下書き記事の追加
     * @param {Number} articleId 記事の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/NewDraftArticles} opts.newDraftArticles 追加する下書き記事の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    postDraftArticle(articleId, opts) {
      return this.postDraftArticleWithHttpInfo(articleId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
