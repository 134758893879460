/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import UserRoleUpdateValues from './UserRoleUpdateValues';





/**
* The UserUpdateValues model module.
* @module model/UserUpdateValues
* @version 1.1.71
*/
export default class UserUpdateValues {
    /**
    * Constructs a new <code>UserUpdateValues</code>.
    * @alias module:model/UserUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>UserUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserUpdateValues} obj Optional instance to populate.
    * @return {module:model/UserUpdateValues} The populated <code>UserUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserUpdateValues();

            
            
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = UserRoleUpdateValues.constructFromObject(data['role']);
            }
        }
        return obj;
    }

    /**
    * @member {String} username
    */
    username = undefined;
    /**
    * @member {String} password
    */
    password = undefined;
    /**
    * @member {module:model/UserRoleUpdateValues} role
    */
    role = undefined;








}


