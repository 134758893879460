/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import CouponBrand from './CouponBrand';
import CouponImage from './CouponImage';
import CouponStatus from './CouponStatus';
import CouponSummary from './CouponSummary';





/**
* The CouponDetail model module.
* @module model/CouponDetail
* @version 1.1.71
*/
export default class CouponDetail {
    /**
    * Constructs a new <code>CouponDetail</code>.
    * @alias module:model/CouponDetail
    * @class
    * @param id {Number} 
    * @param startDateOfUse {String} 
    * @param endDateOfUse {String} 
    * @param status {module:model/CouponStatus} 
    * @param termsOfUse {String} 
    */

    constructor(id, startDateOfUse, endDateOfUse, status, termsOfUse) {
        


        this['id'] = id;this['startDateOfUse'] = startDateOfUse;this['endDateOfUse'] = endDateOfUse;this['status'] = status;this['termsOfUse'] = termsOfUse;

        
    }

    /**
    * Constructs a <code>CouponDetail</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/CouponDetail} obj Optional instance to populate.
    * @return {module:model/CouponDetail} The populated <code>CouponDetail</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CouponDetail();

            
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('couponBrand')) {
                obj['couponBrand'] = CouponBrand.constructFromObject(data['couponBrand']);
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('image')) {
                obj['image'] = CouponImage.constructFromObject(data['image']);
            }
            if (data.hasOwnProperty('startDateOfUse')) {
                obj['startDateOfUse'] = ApiClient.convertToType(data['startDateOfUse'], 'String');
            }
            if (data.hasOwnProperty('endDateOfUse')) {
                obj['endDateOfUse'] = ApiClient.convertToType(data['endDateOfUse'], 'String');
            }
            if (data.hasOwnProperty('weight')) {
                obj['weight'] = ApiClient.convertToType(data['weight'], 'Number');
            }
            if (data.hasOwnProperty('views')) {
                obj['views'] = ApiClient.convertToType(data['views'], 'Number');
            }
            if (data.hasOwnProperty('score')) {
                obj['score'] = ApiClient.convertToType(data['score'], 'Number');
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = CouponStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('notes')) {
                obj['notes'] = ApiClient.convertToType(data['notes'], 'String');
            }
            if (data.hasOwnProperty('termsOfUse')) {
                obj['termsOfUse'] = ApiClient.convertToType(data['termsOfUse'], 'String');
            }
        }
        return obj;
    }

    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {module:model/CouponBrand} couponBrand
    */
    couponBrand = undefined;
    /**
    * @member {String} code
    */
    code = undefined;
    /**
    * @member {module:model/CouponImage} image
    */
    image = undefined;
    /**
    * @member {String} startDateOfUse
    */
    startDateOfUse = undefined;
    /**
    * @member {String} endDateOfUse
    */
    endDateOfUse = undefined;
    /**
    * @member {Number} weight
    */
    weight = undefined;
    /**
    * @member {Number} views
    */
    views = undefined;
    /**
    * @member {Number} score
    */
    score = undefined;
    /**
    * @member {Number} priority
    */
    priority = undefined;
    /**
    * @member {module:model/CouponStatus} status
    */
    status = undefined;
    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {String} notes
    */
    notes = undefined;
    /**
    * @member {String} termsOfUse
    */
    termsOfUse = undefined;








}


