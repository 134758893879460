/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';





/**
* The NotificationTargetUpdateValues model module.
* @module model/NotificationTargetUpdateValues
* @version 1.1.71
*/
export default class NotificationTargetUpdateValues {
    /**
    * Constructs a new <code>NotificationTargetUpdateValues</code>.
    * @alias module:model/NotificationTargetUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>NotificationTargetUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NotificationTargetUpdateValues} obj Optional instance to populate.
    * @return {module:model/NotificationTargetUpdateValues} The populated <code>NotificationTargetUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new NotificationTargetUpdateValues();

            
            
            if (data.hasOwnProperty('ios')) {
                obj['ios'] = ApiClient.convertToType(data['ios'], 'Boolean');
            }
            if (data.hasOwnProperty('androis')) {
                obj['androis'] = ApiClient.convertToType(data['androis'], 'Boolean');
            }
            if (data.hasOwnProperty('web')) {
                obj['web'] = ApiClient.convertToType(data['web'], 'Boolean');
            }
        }
        return obj;
    }

    /**
    * @member {Boolean} ios
    */
    ios = undefined;
    /**
    * @member {Boolean} androis
    */
    androis = undefined;
    /**
    * @member {Boolean} web
    */
    web = undefined;








}


