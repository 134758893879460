/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Cp from '../model/Cp';
import CpUpdateValues from '../model/CpUpdateValues';
import NewCp from '../model/NewCp';

/**
* Cp service.
* @module api/CpApi
* @version 1.1.71
*/
export default class CpApi {

    /**
    * Constructs a new CpApi. 
    * @alias module:api/CpApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * CP の削除
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCpWithHttpInfo(cpId) {
      let postBody = null;

      // verify the required parameter 'cpId' is set
      if (cpId === undefined || cpId === null) {
        throw new Error("Missing the required parameter 'cpId' when calling deleteCp");
      }


      let pathParams = {
        'cpId': cpId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/cps/{cpId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の削除
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCp(cpId) {
      return this.deleteCpWithHttpInfo(cpId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * CP の詳細
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Cp} and HTTP response
     */
    getCpWithHttpInfo(cpId) {
      let postBody = null;

      // verify the required parameter 'cpId' is set
      if (cpId === undefined || cpId === null) {
        throw new Error("Missing the required parameter 'cpId' when calling getCp");
      }


      let pathParams = {
        'cpId': cpId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Cp;

      return this.apiClient.callApi(
        '/cps/{cpId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の詳細
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Cp}
     */
    getCp(cpId) {
      return this.getCpWithHttpInfo(cpId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * CP の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Cp>} and HTTP response
     */
    getCpsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Cp];

      return this.apiClient.callApi(
        '/cps', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Cp>}
     */
    getCps(opts) {
      return this.getCpsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * CP の更新
     * @param {Number} cpId CP の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CpUpdateValues} opts.cpUpdateValues 更新する CP の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Cp} and HTTP response
     */
    patchCpWithHttpInfo(cpId, opts) {
      opts = opts || {};
      let postBody = opts['cpUpdateValues'];

      // verify the required parameter 'cpId' is set
      if (cpId === undefined || cpId === null) {
        throw new Error("Missing the required parameter 'cpId' when calling patchCp");
      }


      let pathParams = {
        'cpId': cpId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Cp;

      return this.apiClient.callApi(
        '/cps/{cpId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の更新
     * @param {Number} cpId CP の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CpUpdateValues} opts.cpUpdateValues 更新する CP の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Cp}
     */
    patchCp(cpId, opts) {
      return this.patchCpWithHttpInfo(cpId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * CP の追加
     * @param {module:model/NewCp} newCp 追加する CP の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Cp} and HTTP response
     */
    postCpWithHttpInfo(newCp) {
      let postBody = newCp;

      // verify the required parameter 'newCp' is set
      if (newCp === undefined || newCp === null) {
        throw new Error("Missing the required parameter 'newCp' when calling postCp");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Cp;

      return this.apiClient.callApi(
        '/cps', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の追加
     * @param {module:model/NewCp} newCp 追加する CP の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Cp}
     */
    postCp(newCp) {
      return this.postCpWithHttpInfo(newCp)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * CP の復元
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putCpWithHttpInfo(cpId) {
      let postBody = null;

      // verify the required parameter 'cpId' is set
      if (cpId === undefined || cpId === null) {
        throw new Error("Missing the required parameter 'cpId' when calling putCp");
      }


      let pathParams = {
        'cpId': cpId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/cps/{cpId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * CP の復元
     * @param {Number} cpId CP の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putCp(cpId) {
      return this.putCpWithHttpInfo(cpId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
