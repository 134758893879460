/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Creator from '../model/Creator';
import CreatorUpdateValues from '../model/CreatorUpdateValues';
import NewCreator from '../model/NewCreator';

/**
* Creator service.
* @module api/CreatorApi
* @version 1.1.71
*/
export default class CreatorApi {

    /**
    * Constructs a new CreatorApi. 
    * @alias module:api/CreatorApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * クリエイターの削除
     * @param {Number} creatorId クリエイターの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteCreatorWithHttpInfo(creatorId) {
      let postBody = null;

      // verify the required parameter 'creatorId' is set
      if (creatorId === undefined || creatorId === null) {
        throw new Error("Missing the required parameter 'creatorId' when calling deleteCreator");
      }


      let pathParams = {
        'creatorId': creatorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/creators/{creatorId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クリエイターの削除
     * @param {Number} creatorId クリエイターの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteCreator(creatorId) {
      return this.deleteCreatorWithHttpInfo(creatorId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クリエイターの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Creator>} and HTTP response
     */
    getCreatorsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Creator];

      return this.apiClient.callApi(
        '/creators', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クリエイターの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Creator>}
     */
    getCreators(opts) {
      return this.getCreatorsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クリエイターの更新
     * @param {Number} creatorId クリエイターの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CreatorUpdateValues} opts.creatorUpdateValues 更新するクリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Creator} and HTTP response
     */
    patchCreatorWithHttpInfo(creatorId, opts) {
      opts = opts || {};
      let postBody = opts['creatorUpdateValues'];

      // verify the required parameter 'creatorId' is set
      if (creatorId === undefined || creatorId === null) {
        throw new Error("Missing the required parameter 'creatorId' when calling patchCreator");
      }


      let pathParams = {
        'creatorId': creatorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Creator;

      return this.apiClient.callApi(
        '/creators/{creatorId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クリエイターの更新
     * @param {Number} creatorId クリエイターの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/CreatorUpdateValues} opts.creatorUpdateValues 更新するクリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Creator}
     */
    patchCreator(creatorId, opts) {
      return this.patchCreatorWithHttpInfo(creatorId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クリエイターの追加
     * @param {module:model/NewCreator} newCreator 追加するクリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Creator} and HTTP response
     */
    postCreatorWithHttpInfo(newCreator) {
      let postBody = newCreator;

      // verify the required parameter 'newCreator' is set
      if (newCreator === undefined || newCreator === null) {
        throw new Error("Missing the required parameter 'newCreator' when calling postCreator");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Creator;

      return this.apiClient.callApi(
        '/creators', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クリエイターの追加
     * @param {module:model/NewCreator} newCreator 追加するクリエイターの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Creator}
     */
    postCreator(newCreator) {
      return this.postCreatorWithHttpInfo(newCreator)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * クリエイターの復元
     * @param {Number} creatorId クリエイターの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putCreatorWithHttpInfo(creatorId) {
      let postBody = null;

      // verify the required parameter 'creatorId' is set
      if (creatorId === undefined || creatorId === null) {
        throw new Error("Missing the required parameter 'creatorId' when calling putCreator");
      }


      let pathParams = {
        'creatorId': creatorId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/creators/{creatorId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * クリエイターの復元
     * @param {Number} creatorId クリエイターの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putCreator(creatorId) {
      return this.putCreatorWithHttpInfo(creatorId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
