/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import RestrictedWordType from './RestrictedWordType';
import Timestamp from './Timestamp';





/**
* The RestrictedWord model module.
* @module model/RestrictedWord
* @version 1.1.71
*/
export default class RestrictedWord {
    /**
    * Constructs a new <code>RestrictedWord</code>.
    * @alias module:model/RestrictedWord
    * @class
    * @param id {Number} 
    * @param word {String} 
    * @param wordType {module:model/RestrictedWordType} 
    */

    constructor(id, word, wordType) {
        


        this['id'] = id;this['word'] = word;this['wordType'] = wordType;

        
    }

    /**
    * Constructs a <code>RestrictedWord</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/RestrictedWord} obj Optional instance to populate.
    * @return {module:model/RestrictedWord} The populated <code>RestrictedWord</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RestrictedWord();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('word')) {
                obj['word'] = ApiClient.convertToType(data['word'], 'String');
            }
            if (data.hasOwnProperty('wordType')) {
                obj['wordType'] = RestrictedWordType.constructFromObject(data['wordType']);
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} word
    */
    word = undefined;
    /**
    * @member {module:model/RestrictedWordType} wordType
    */
    wordType = undefined;








}


