/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewTagGroup from '../model/NewTagGroup';
import Tag from '../model/Tag';
import TagGroup from '../model/TagGroup';
import TagGroupUpdateValues from '../model/TagGroupUpdateValues';

/**
* TagGroup service.
* @module api/TagGroupApi
* @version 1.1.71
*/
export default class TagGroupApi {

    /**
    * Constructs a new TagGroupApi. 
    * @alias module:api/TagGroupApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * タグ・グループの削除
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteTagGroupWithHttpInfo(tagGroupId) {
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling deleteTagGroup");
      }


      let pathParams = {
        'tagGroupId': tagGroupId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの削除
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteTagGroup(tagGroupId) {
      return this.deleteTagGroupWithHttpInfo(tagGroupId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループに関連するタグの削除
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteTagGroupTagWithHttpInfo(tagGroupId, tagId) {
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling deleteTagGroupTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling deleteTagGroupTag");
      }


      let pathParams = {
        'tagGroupId': tagGroupId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}/tags/{tagId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループに関連するタグの削除
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteTagGroupTag(tagGroupId, tagId) {
      return this.deleteTagGroupTagWithHttpInfo(tagGroupId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループの詳細
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagGroup} and HTTP response
     */
    getTagGroupWithHttpInfo(tagGroupId) {
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling getTagGroup");
      }


      let pathParams = {
        'tagGroupId': tagGroupId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = TagGroup;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの詳細
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagGroup}
     */
    getTagGroup(tagGroupId) {
      return this.getTagGroupWithHttpInfo(tagGroupId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループに関連するタグの一覧
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Tag>} and HTTP response
     */
    getTagGroupTagsWithHttpInfo(tagGroupId, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling getTagGroupTags");
      }


      let pathParams = {
        'tagGroupId': tagGroupId
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Tag];

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}/tags', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループに関連するタグの一覧
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Tag>}
     */
    getTagGroupTags(tagGroupId, opts) {
      return this.getTagGroupTagsWithHttpInfo(tagGroupId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/TagGroup>} and HTTP response
     */
    getTagGroupsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [TagGroup];

      return this.apiClient.callApi(
        '/tagGroups', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/TagGroup>}
     */
    getTagGroups(opts) {
      return this.getTagGroupsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループの更新
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/TagGroupUpdateValues} opts.tagGroupUpdateValues 更新するタグ・グループの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagGroup} and HTTP response
     */
    patchTagGroupWithHttpInfo(tagGroupId, opts) {
      opts = opts || {};
      let postBody = opts['tagGroupUpdateValues'];

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling patchTagGroup");
      }


      let pathParams = {
        'tagGroupId': tagGroupId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = TagGroup;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの更新
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/TagGroupUpdateValues} opts.tagGroupUpdateValues 更新するタグ・グループの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagGroup}
     */
    patchTagGroup(tagGroupId, opts) {
      return this.patchTagGroupWithHttpInfo(tagGroupId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループの追加
     * @param {module:model/NewTagGroup} newTagGroup 追加するタグ・グループの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/TagGroup} and HTTP response
     */
    postTagGroupWithHttpInfo(newTagGroup) {
      let postBody = newTagGroup;

      // verify the required parameter 'newTagGroup' is set
      if (newTagGroup === undefined || newTagGroup === null) {
        throw new Error("Missing the required parameter 'newTagGroup' when calling postTagGroup");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = TagGroup;

      return this.apiClient.callApi(
        '/tagGroups', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの追加
     * @param {module:model/NewTagGroup} newTagGroup 追加するタグ・グループの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/TagGroup}
     */
    postTagGroup(newTagGroup) {
      return this.postTagGroupWithHttpInfo(newTagGroup)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループの復元
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putTagGroupWithHttpInfo(tagGroupId) {
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling putTagGroup");
      }


      let pathParams = {
        'tagGroupId': tagGroupId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループの復元
     * @param {Number} tagGroupId タグ・グループの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putTagGroup(tagGroupId) {
      return this.putTagGroupWithHttpInfo(tagGroupId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * タグ・グループに関連するタグの追加
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putTagGroupTagWithHttpInfo(tagGroupId, tagId) {
      let postBody = null;

      // verify the required parameter 'tagGroupId' is set
      if (tagGroupId === undefined || tagGroupId === null) {
        throw new Error("Missing the required parameter 'tagGroupId' when calling putTagGroupTag");
      }

      // verify the required parameter 'tagId' is set
      if (tagId === undefined || tagId === null) {
        throw new Error("Missing the required parameter 'tagId' when calling putTagGroupTag");
      }


      let pathParams = {
        'tagGroupId': tagGroupId,
        'tagId': tagId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/tagGroups/{tagGroupId}/tags/{tagId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * タグ・グループに関連するタグの追加
     * @param {Number} tagGroupId タグ・グループの ID
     * @param {Number} tagId タグの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putTagGroupTag(tagGroupId, tagId) {
      return this.putTagGroupTagWithHttpInfo(tagGroupId, tagId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
