/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ArticleCoverUpdateValues from './ArticleCoverUpdateValues';
import ArticleStatusUpdateValues from './ArticleStatusUpdateValues';
import ArticleThumbnailUpdateValues from './ArticleThumbnailUpdateValues';
import MediumRelatedArticlesUpdateValues from './MediumRelatedArticlesUpdateValues';
import OriginalArticle from './OriginalArticle';





/**
* The ArticleUpdateValues model module.
* @module model/ArticleUpdateValues
* @version 1.1.71
*/
export default class ArticleUpdateValues {
    /**
    * Constructs a new <code>ArticleUpdateValues</code>.
    * @alias module:model/ArticleUpdateValues
    * @class
    */

    constructor() {
        


        

        
    }

    /**
    * Constructs a <code>ArticleUpdateValues</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ArticleUpdateValues} obj Optional instance to populate.
    * @return {module:model/ArticleUpdateValues} The populated <code>ArticleUpdateValues</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ArticleUpdateValues();

            
            
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = ArticleThumbnailUpdateValues.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = ArticleCoverUpdateValues.constructFromObject(data['cover']);
            }
            if (data.hasOwnProperty('mediumRelatedArticles')) {
                obj['mediumRelatedArticles'] = MediumRelatedArticlesUpdateValues.constructFromObject(data['mediumRelatedArticles']);
            }
            if (data.hasOwnProperty('mediumItemId')) {
                obj['mediumItemId'] = ApiClient.convertToType(data['mediumItemId'], 'Number');
            }
            if (data.hasOwnProperty('categoryId')) {
                obj['categoryId'] = ApiClient.convertToType(data['categoryId'], 'Number');
            }
            if (data.hasOwnProperty('creatorId')) {
                obj['creatorId'] = ApiClient.convertToType(data['creatorId'], 'Number');
            }
            if (data.hasOwnProperty('seriesId')) {
                obj['seriesId'] = ApiClient.convertToType(data['seriesId'], 'Number');
            }
            if (data.hasOwnProperty('sponsorId')) {
                obj['sponsorId'] = ApiClient.convertToType(data['sponsorId'], 'Number');
            }
            if (data.hasOwnProperty('isAffiliated')) {
                obj['isAffiliated'] = ApiClient.convertToType(data['isAffiliated'], 'Boolean');
            }
            if (data.hasOwnProperty('isPinterestAutoPublish')) {
                obj['isPinterestAutoPublish'] = ApiClient.convertToType(data['isPinterestAutoPublish'], 'Boolean');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ArticleStatusUpdateValues.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('originalArticle')) {
                obj['originalArticle'] = OriginalArticle.constructFromObject(data['originalArticle']);
            }
        }
        return obj;
    }

    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {String} summary
    */
    summary = undefined;
    /**
    * @member {String} description
    */
    description = undefined;
    /**
    * @member {module:model/ArticleThumbnailUpdateValues} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/ArticleCoverUpdateValues} cover
    */
    cover = undefined;
    /**
    * @member {module:model/MediumRelatedArticlesUpdateValues} mediumRelatedArticles
    */
    mediumRelatedArticles = undefined;
    /**
    * @member {Number} mediumItemId
    */
    mediumItemId = undefined;
    /**
    * @member {Number} categoryId
    */
    categoryId = undefined;
    /**
    * @member {Number} creatorId
    */
    creatorId = undefined;
    /**
    * @member {Number} seriesId
    */
    seriesId = undefined;
    /**
    * @member {Number} sponsorId
    */
    sponsorId = undefined;
    /**
    * @member {Boolean} isAffiliated
    */
    isAffiliated = undefined;
    /**
    * @member {Boolean} isPinterestAutoPublish
    */
    isPinterestAutoPublish = undefined;
    /**
    * @member {module:model/ArticleStatusUpdateValues} status
    */
    status = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {module:model/OriginalArticle} originalArticle
    */
    originalArticle = undefined;








}


