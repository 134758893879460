/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewRestrictedWord from '../model/NewRestrictedWord';
import RestrictedWord from '../model/RestrictedWord';
import RestrictedWordUpdateValues from '../model/RestrictedWordUpdateValues';

/**
* RestrictedWord service.
* @module api/RestrictedWordApi
* @version 1.1.71
*/
export default class RestrictedWordApi {

    /**
    * Constructs a new RestrictedWordApi. 
    * @alias module:api/RestrictedWordApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 制限語の削除
     * @param {Number} restrictedWordId 制限語の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteRestrictedWordWithHttpInfo(restrictedWordId) {
      let postBody = null;

      // verify the required parameter 'restrictedWordId' is set
      if (restrictedWordId === undefined || restrictedWordId === null) {
        throw new Error("Missing the required parameter 'restrictedWordId' when calling deleteRestrictedWord");
      }


      let pathParams = {
        'restrictedWordId': restrictedWordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/restrictedWords/{restrictedWordId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 制限語の削除
     * @param {Number} restrictedWordId 制限語の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteRestrictedWord(restrictedWordId) {
      return this.deleteRestrictedWordWithHttpInfo(restrictedWordId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 制限語の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/RestrictedWord>} and HTTP response
     */
    getRestrictedWordsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [RestrictedWord];

      return this.apiClient.callApi(
        '/restrictedWords', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 制限語の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/RestrictedWord>}
     */
    getRestrictedWords(opts) {
      return this.getRestrictedWordsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 制限語の更新
     * @param {Number} restrictedWordId 制限語の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/RestrictedWordUpdateValues} opts.restrictedWordUpdateValues 更新する制限語の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestrictedWord} and HTTP response
     */
    patchRestrictedWordWithHttpInfo(restrictedWordId, opts) {
      opts = opts || {};
      let postBody = opts['restrictedWordUpdateValues'];

      // verify the required parameter 'restrictedWordId' is set
      if (restrictedWordId === undefined || restrictedWordId === null) {
        throw new Error("Missing the required parameter 'restrictedWordId' when calling patchRestrictedWord");
      }


      let pathParams = {
        'restrictedWordId': restrictedWordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = RestrictedWord;

      return this.apiClient.callApi(
        '/restrictedWords/{restrictedWordId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 制限語の更新
     * @param {Number} restrictedWordId 制限語の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/RestrictedWordUpdateValues} opts.restrictedWordUpdateValues 更新する制限語の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestrictedWord}
     */
    patchRestrictedWord(restrictedWordId, opts) {
      return this.patchRestrictedWordWithHttpInfo(restrictedWordId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 制限語の追加
     * @param {module:model/NewRestrictedWord} newRestrictedWord 追加する制限語の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RestrictedWord} and HTTP response
     */
    postRestrictedWordWithHttpInfo(newRestrictedWord) {
      let postBody = newRestrictedWord;

      // verify the required parameter 'newRestrictedWord' is set
      if (newRestrictedWord === undefined || newRestrictedWord === null) {
        throw new Error("Missing the required parameter 'newRestrictedWord' when calling postRestrictedWord");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = RestrictedWord;

      return this.apiClient.callApi(
        '/restrictedWords', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 制限語の追加
     * @param {module:model/NewRestrictedWord} newRestrictedWord 追加する制限語の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RestrictedWord}
     */
    postRestrictedWord(newRestrictedWord) {
      return this.postRestrictedWordWithHttpInfo(newRestrictedWord)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 制限語の復元
     * @param {Number} restrictedWordId 制限語の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putRestrictedWordWithHttpInfo(restrictedWordId) {
      let postBody = null;

      // verify the required parameter 'restrictedWordId' is set
      if (restrictedWordId === undefined || restrictedWordId === null) {
        throw new Error("Missing the required parameter 'restrictedWordId' when calling putRestrictedWord");
      }


      let pathParams = {
        'restrictedWordId': restrictedWordId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/restrictedWords/{restrictedWordId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 制限語の復元
     * @param {Number} restrictedWordId 制限語の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putRestrictedWord(restrictedWordId) {
      return this.putRestrictedWordWithHttpInfo(restrictedWordId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
