/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';


/**
* Enum class RestrictedWordType.
* @enum {}
* @readonly
*/
export default class RestrictedWordType {
    
        /**
         * value: "ng"
         * @const
         */
        ng = "ng";

    
        /**
         * value: "risk"
         * @const
         */
        risk = "risk";

    

    /**
    * Returns a <code>RestrictedWordType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/RestrictedWordType} The enum <code>RestrictedWordType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}


