/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewSeries from '../model/NewSeries';
import Series from '../model/Series';
import SeriesUpdateValues from '../model/SeriesUpdateValues';

/**
* Series service.
* @module api/SeriesApi
* @version 1.1.71
*/
export default class SeriesApi {

    /**
    * Constructs a new SeriesApi. 
    * @alias module:api/SeriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * 連載の削除
     * @param {Number} seriesId 連載の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteSeriesWithHttpInfo(seriesId) {
      let postBody = null;

      // verify the required parameter 'seriesId' is set
      if (seriesId === undefined || seriesId === null) {
        throw new Error("Missing the required parameter 'seriesId' when calling deleteSeries");
      }


      let pathParams = {
        'seriesId': seriesId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/series/{seriesId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 連載の削除
     * @param {Number} seriesId 連載の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteSeries(seriesId) {
      return this.deleteSeriesWithHttpInfo(seriesId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 連載の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Series>} and HTTP response
     */
    getSeriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [Series];

      return this.apiClient.callApi(
        '/series', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 連載の一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Series>}
     */
    getSeries(opts) {
      return this.getSeriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 連載の更新
     * @param {Number} seriesId 連載の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/SeriesUpdateValues} opts.seriesUpdateValues 更新する連載の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Series} and HTTP response
     */
    patchSeriesWithHttpInfo(seriesId, opts) {
      opts = opts || {};
      let postBody = opts['seriesUpdateValues'];

      // verify the required parameter 'seriesId' is set
      if (seriesId === undefined || seriesId === null) {
        throw new Error("Missing the required parameter 'seriesId' when calling patchSeries");
      }


      let pathParams = {
        'seriesId': seriesId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Series;

      return this.apiClient.callApi(
        '/series/{seriesId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 連載の更新
     * @param {Number} seriesId 連載の ID
     * @param {Object} opts Optional parameters
     * @param {module:model/SeriesUpdateValues} opts.seriesUpdateValues 更新する連載の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Series}
     */
    patchSeries(seriesId, opts) {
      return this.patchSeriesWithHttpInfo(seriesId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 連載の追加
     * @param {module:model/NewSeries} newSeries 追加する連載の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Series} and HTTP response
     */
    postSeriesWithHttpInfo(newSeries) {
      let postBody = newSeries;

      // verify the required parameter 'newSeries' is set
      if (newSeries === undefined || newSeries === null) {
        throw new Error("Missing the required parameter 'newSeries' when calling postSeries");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = Series;

      return this.apiClient.callApi(
        '/series', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 連載の追加
     * @param {module:model/NewSeries} newSeries 追加する連載の内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Series}
     */
    postSeries(newSeries) {
      return this.postSeriesWithHttpInfo(newSeries)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 連載の復元
     * @param {Number} seriesId 連載の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putSeriesWithHttpInfo(seriesId) {
      let postBody = null;

      // verify the required parameter 'seriesId' is set
      if (seriesId === undefined || seriesId === null) {
        throw new Error("Missing the required parameter 'seriesId' when calling putSeries");
      }


      let pathParams = {
        'seriesId': seriesId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/series/{seriesId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 連載の復元
     * @param {Number} seriesId 連載の ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putSeries(seriesId) {
      return this.putSeriesWithHttpInfo(seriesId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
