/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import NewUser from '../model/NewUser';
import PasswordUpdateValues from '../model/PasswordUpdateValues';
import User from '../model/User';
import UserMe from '../model/UserMe';
import UserUpdateValues from '../model/UserUpdateValues';

/**
* User service.
* @module api/UserApi
* @version 1.1.71
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * ユーザーの削除
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    deleteUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling deleteUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/users/{userId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの削除
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    deleteUser(userId) {
      return this.deleteUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * サインイン中のユーザーの詳細
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserMe} and HTTP response
     */
    getCurrentUserWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = UserMe;

      return this.apiClient.callApi(
        '/users/me', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * サインイン中のユーザーの詳細
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserMe}
     */
    getCurrentUser() {
      return this.getCurrentUserWithHttpInfo()
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ユーザーの詳細
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
     */
    getUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling getUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = User;

      return this.apiClient.callApi(
        '/users/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの詳細
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
     */
    getUser(userId) {
      return this.getUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ユーザーの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/User>} and HTTP response
     */
    getUsersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'filtering': this.apiClient.buildCollectionParam(opts['filtering'], 'csv'),
        'sorting': this.apiClient.buildCollectionParam(opts['sorting'], 'csv'),
        'itemsPerPage': opts['itemsPerPage'],
        'currentPage': opts['currentPage']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = [User];

      return this.apiClient.callApi(
        '/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの一覧
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.filtering 絞り込みオプションの配列 （例：&#x60;publishDatetime &gt;&#x3D; &#39;2017-12-25T00:00:00Z&#39;&#x60;, &#x60;status &#x3D; &#39;publish&#39;&#x60;, &#x60;mediumId &lt;&gt; 7&#x60;） 
     * @param {Array.<String>} opts.sorting 並べ替えするフィールド名の配列（降り順のフィールドは &#x60;-&#x60; 接頭辞を付ける）
     * @param {Number} opts.itemsPerPage ページ当たりのアイテム数 (default to 10)
     * @param {Number} opts.currentPage 取得するページの番号 (default to 1)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/User>}
     */
    getUsers(opts) {
      return this.getUsersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * 自身のパスワード更新
     * @param {String} userId 自身のユーザーの ID
     * @param {module:model/PasswordUpdateValues} passwordUpdateValues 更新するパスワードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    patchPasswordWithHttpInfo(userId, passwordUpdateValues) {
      let postBody = passwordUpdateValues;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling patchPassword");
      }

      // verify the required parameter 'passwordUpdateValues' is set
      if (passwordUpdateValues === undefined || passwordUpdateValues === null) {
        throw new Error("Missing the required parameter 'passwordUpdateValues' when calling patchPassword");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/users/{userId}/updatePassword', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * 自身のパスワード更新
     * @param {String} userId 自身のユーザーの ID
     * @param {module:model/PasswordUpdateValues} passwordUpdateValues 更新するパスワードの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    patchPassword(userId, passwordUpdateValues) {
      return this.patchPasswordWithHttpInfo(userId, passwordUpdateValues)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ユーザーの更新
     * @param {String} userId ユーザーの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/UserUpdateValues} opts.userUpdateValues 更新するユーザーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
     */
    patchUserWithHttpInfo(userId, opts) {
      opts = opts || {};
      let postBody = opts['userUpdateValues'];

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling patchUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = User;

      return this.apiClient.callApi(
        '/users/{userId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの更新
     * @param {String} userId ユーザーの ID
     * @param {Object} opts Optional parameters
     * @param {module:model/UserUpdateValues} opts.userUpdateValues 更新するユーザーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
     */
    patchUser(userId, opts) {
      return this.patchUserWithHttpInfo(userId, opts)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ユーザーの追加
     * @param {module:model/NewUser} newUser 追加するユーザーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/User} and HTTP response
     */
    postUserWithHttpInfo(newUser) {
      let postBody = newUser;

      // verify the required parameter 'newUser' is set
      if (newUser === undefined || newUser === null) {
        throw new Error("Missing the required parameter 'newUser' when calling postUser");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = User;

      return this.apiClient.callApi(
        '/users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの追加
     * @param {module:model/NewUser} newUser 追加するユーザーの内容
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/User}
     */
    postUser(newUser) {
      return this.postUserWithHttpInfo(newUser)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


    /**
     * ユーザーの復元
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    putUserWithHttpInfo(userId) {
      let postBody = null;

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling putUser");
      }


      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['OAuth2'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['application/json', 'application/csv', 'text/plain'];
      let returnType = null;

      return this.apiClient.callApi(
        '/users/{userId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * ユーザーの復元
     * @param {String} userId ユーザーの ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    putUser(userId) {
      return this.putUserWithHttpInfo(userId)
        .then(function(response_and_data) {
          return {
            statusCode: response_and_data.response.statusCode,
            header: response_and_data.response.header,
            data: response_and_data.data
          };
        });
    }


}
