/**
 * TRILL API ADMIN
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.1.71
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */


import ApiClient from '../ApiClient';
import ArticleStatus from './ArticleStatus';
import ArticleThumbnail from './ArticleThumbnail';
import Category from './Category';
import ManagementCategory from './ManagementCategory';
import MatchedRestrictedWords from './MatchedRestrictedWords';
import MediumItem from './MediumItem';
import MediumSummary from './MediumSummary';
import Sponsor from './Sponsor';
import Timestamp from './Timestamp';
import Views from './Views';





/**
* The ArticleSummary model module.
* @module model/ArticleSummary
* @version 1.1.71
*/
export default class ArticleSummary {
    /**
    * Constructs a new <code>ArticleSummary</code>.
    * @alias module:model/ArticleSummary
    * @class
    * @param id {Number} 
    * @param title {String} 
    * @param medium {module:model/MediumSummary} 
    * @param viewsTotal {Number} 
    */

    constructor(id, title, medium, viewsTotal) {
        


        this['id'] = id;this['title'] = title;this['medium'] = medium;this['viewsTotal'] = viewsTotal;

        
    }

    /**
    * Constructs a <code>ArticleSummary</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ArticleSummary} obj Optional instance to populate.
    * @return {module:model/ArticleSummary} The populated <code>ArticleSummary</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ArticleSummary();

            
            
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('slug')) {
                obj['slug'] = ApiClient.convertToType(data['slug'], 'String');
            }
            if (data.hasOwnProperty('thumbnail')) {
                obj['thumbnail'] = ArticleThumbnail.constructFromObject(data['thumbnail']);
            }
            if (data.hasOwnProperty('medium')) {
                obj['medium'] = MediumSummary.constructFromObject(data['medium']);
            }
            if (data.hasOwnProperty('mediumItem')) {
                obj['mediumItem'] = MediumItem.constructFromObject(data['mediumItem']);
            }
            if (data.hasOwnProperty('category')) {
                obj['category'] = Category.constructFromObject(data['category']);
            }
            if (data.hasOwnProperty('managementCategory')) {
                obj['managementCategory'] = ManagementCategory.constructFromObject(data['managementCategory']);
            }
            if (data.hasOwnProperty('sponsor')) {
                obj['sponsor'] = Sponsor.constructFromObject(data['sponsor']);
            }
            if (data.hasOwnProperty('views')) {
                obj['views'] = Views.constructFromObject(data['views']);
            }
            if (data.hasOwnProperty('viewsTotal')) {
                obj['viewsTotal'] = ApiClient.convertToType(data['viewsTotal'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ArticleStatus.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('publishDatetime')) {
                obj['publishDatetime'] = ApiClient.convertToType(data['publishDatetime'], 'String');
            }
            if (data.hasOwnProperty('hotFactor')) {
                obj['hotFactor'] = ApiClient.convertToType(data['hotFactor'], 'Number');
            }
            if (data.hasOwnProperty('restrictedWords')) {
                obj['restrictedWords'] = MatchedRestrictedWords.constructFromObject(data['restrictedWords']);
            }
        }
        return obj;
    }

    /**
    * @member {String} createdAt
    */
    createdAt = undefined;
    /**
    * @member {String} updatedAt
    */
    updatedAt = undefined;
    /**
    * @member {String} deletedAt
    */
    deletedAt = undefined;
    /**
    * @member {Number} id
    */
    id = undefined;
    /**
    * @member {String} title
    */
    title = undefined;
    /**
    * @member {String} slug
    */
    slug = undefined;
    /**
    * @member {module:model/ArticleThumbnail} thumbnail
    */
    thumbnail = undefined;
    /**
    * @member {module:model/MediumSummary} medium
    */
    medium = undefined;
    /**
    * @member {module:model/MediumItem} mediumItem
    */
    mediumItem = undefined;
    /**
    * @member {module:model/Category} category
    */
    category = undefined;
    /**
    * @member {module:model/ManagementCategory} managementCategory
    */
    managementCategory = undefined;
    /**
    * @member {module:model/Sponsor} sponsor
    */
    sponsor = undefined;
    /**
    * @member {module:model/Views} views
    */
    views = undefined;
    /**
    * @member {Number} viewsTotal
    */
    viewsTotal = undefined;
    /**
    * @member {module:model/ArticleStatus} status
    */
    status = undefined;
    /**
    * @member {String} publishDatetime
    */
    publishDatetime = undefined;
    /**
    * @member {Number} hotFactor
    */
    hotFactor = undefined;
    /**
    * @member {module:model/MatchedRestrictedWords} restrictedWords
    */
    restrictedWords = undefined;








}


